@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.cardContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color:  rgb(238, 238, 238);
  padding: 0 1rem;
  /* padding-right: 1.2rem; */
  border-radius: 0.5rem;
  position: relative;
}

.cardContUp {
  font-family: 'Kaushan Script', serif!important;
  font-size: 1.8rem!important;
  color: rgb(60, 60, 60);
  letter-spacing: 0.2rem!important;
  border-radius: 50%;
  /* height: max-content; */
  display: flex;
  justify-content: center;
  align-items: center;
}