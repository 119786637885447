.container {
    position: fixed;
    bottom: 5em;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.container > * {
    margin: 0 5px !important;
}