.button {
    border: 1px solid #ced4da !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.input {
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}