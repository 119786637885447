.info {
    padding: 10px 50px;
    max-width: 50%;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcomeText {
    padding: 5px 30px;
    line-height: 2;
}
