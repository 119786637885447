.container {
    padding: 50px 70px;
}

.cardsGroup {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.cardsGroup > * {
    margin: 10px;
    width: min(40%, 25em);
}

.cardContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cardsTitle {
    min-height: 3em;
}

.icon {
    font-size: 4em !important;
}