/* .swiper-wrapper {
    padding: 20px;
} */

.mySwiper .swiper-button-next, .mySwiper .swiper-button-prev {
    height: 100%;
    top: 0;
    margin-top: 0;
    padding: 20px;
}

.mySwiper .swiper-button-next {
    right: 0;
}

.mySwiper .swiper-button-prev {
    left: 0;
}

.mySwiper .swiper-button-disabled {
    display: none;
}

.mySwiper .swiper-slide {
    margin-right: 20px;
}

.carousel {
    /* margin: 3vw 0; */
}

.swiper-slide {
    width: auto !important;
}