.input {
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.selectInput {
    width: 60px;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}