.avatar {
    width: 256px !important;
    height: 256px !important;
    border-radius: 4px !important;
    margin-bottom: 10px;
}

.card {
    min-width: 100%;
    width: fit-content;
}

.descriptionContainer::-webkit-scrollbar {
    width: 10px;
}