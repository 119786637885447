.cardList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 15px;
}

.cardList > * {
    margin: 10px;
}
