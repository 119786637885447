.mobile {
    width: fit-content;
    height: fit-content;
    background:  linear-gradient(114deg, rgba(9,9,9,1) 5%, rgba(57,57,57,1) 35%, rgba(9,9,9,1) 35%);
    border: 1em solid black;
    border-top-width: 4em;
    border-bottom-width: 4em;
    border-radius: 24px;
    position: relative;
    margin: 1em 0;
    box-shadow: rgb(0 0 0 / 26%) 0px 1rem 4rem, rgb(43 43 43 / 24%) 0px 0px 0px 0.1rem;
}

.audio {
    width: 60px;
    height: 5px;
    border-radius: 10px;
    background-color: rgb(36, 36, 41);
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.menu {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: rgb(36, 36, 41);
    position: absolute;
    bottom: -67.5px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.screen {
    width: 230px;
    height: 370px;
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.message {
    font-size: 1.3rem;
    text-align: center;
    color: white!important;
    padding: 1rem;
}