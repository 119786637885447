.footer {
    width: 100%;
    background-color: rgb(1, 31, 62);
    color: white;
    /* padding: 3em 9em 1em; */
    padding: 3% 9% 1%;
    vertical-align: baseline;
}

.footer #copyright {
    margin-top: 45px;
}