.recommendedCard {
  position: relative;
  height: max-content;
  overflow: hidden; 
  flex-basis: max-content;

  animation: add_anim 200ms ease forwards;
}

@keyframes add_anim {
  0% {
    transform: translateY(-100%);
    opacity: 0.2;
    max-height: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    max-height: 4rem;
  }
}

.removeCardAnimation {
  position: relative;
  width: max-content;
  overflow: hidden; 
  flex-basis: max-content;
  
  animation: remove_anim 200ms ease forwards;
}

@keyframes remove_anim {
  0% {
    transform: translateY(0);
    opacity: 1;
    max-height: 4rem;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0.2;
    max-height: 0;
  }
}

