.container {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    /* z-index: 9999; */
    opacity: 0;
    pointer-events: none;
}

.playButton {
    color: white !important;
}