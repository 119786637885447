.container {
    padding: 2em 0;
    padding-bottom: 0;
}

.box {
    margin: 0 auto;
    margin-bottom: 5em;
    max-width: 450px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
}