.box {
    margin: 2em auto;
    margin-bottom: 5em;
    max-width: 450px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    padding: 60px;
}

.form > * {
    margin: 5px !important;
}

@media only screen and (max-width: 500px) {

    .box {
        margin: none;
        max-width: max-content;
        border-radius: 0px;
        height: 100vh;
    }
}