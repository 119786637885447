.card {
    width: 10em;
    margin: 1em;
    cursor: pointer;
    position: relative;
    transition: all .25 linear;
}

.card:hover {
    box-shadow: -1px 10px 15px 0px rgba(0,0,0,0.4);
}

.card:active {
    transform: scale(0.9);
}

.content {
    height: 5em;
    overflow-y: scroll;
}

.closeButton {
    position: absolute !important;
    right: 5px !important;
}