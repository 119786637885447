.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
}

.textContainer {
    padding: 30px 20px;
    background: black;
    color: white;
}