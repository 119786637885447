.recipient-card {
  background-color: #F5F5F5;
  border-radius: .5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

.porcentage-input,
.porcentage-input:focus {
  height: 40px;
  width: 50%;
  background-color: white;
  border-radius: .4rem;
  border: 1px solid rgb(163, 163, 163);
  text-align: center;

}

@keyframes animeLeft {
  to {
    opacity: initial;
    transform: initial;
  }
}