.container {
    /* padding: 50px 70px; */
    padding: 4rem 0;
}

.cardsGroup {
    display: flex;
    flex-direction: row;
}

.cardsGroup > * {
    margin: 10px;
    flex: 1;
}

.cardContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cardsTitle {
    min-height: 3em;
}

.icon {
    font-size: 4em !important;
}