.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 6em 0;
    padding-bottom: 0;
}