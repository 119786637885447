.card {
    margin: 5px;
    padding: 1em;
    height: 100%;
    user-select: none;
    cursor: pointer;
    transition: .1s;
}

.card:hover h6 {
    text-decoration: underline;
}