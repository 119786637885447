.logo-header-img {
    height: 3.25em;
    user-select: none;
    -webkit-user-drag: none;
}

.logo-header-img-normal {
    aspect-ratio: 2.5;
}

.logo-header-img-small {
    display: none;
}

@media screen and (max-width: 576px) {
    .logo-header-img-small {
        display: block;
    }

    .logo-header-img-normal {
        display: none;
    }
}