.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.textContainer {
    padding: 30px 20px;
    color: white;
    width: 100%;
    text-align: center;
    padding: 2rem 0;
}

.app-ad-container .owl-item {
    z-index: 1!important;
    transition: 200ms ease;
}

.app-ad-container .owl-item:hover {
    z-index: 10!important;
    transform: scale(1.4);
}

.app-ad-container .owl-stage {
    padding: 6rem 0;
}
