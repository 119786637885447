.getCourseButton {
  width: max-content;
  padding: 1rem 4rem;
  border-radius: 0.8rem;
  outline: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;

  transition: 400ms 1s ease;
}

.getCourseButton:hover,
.getCourseButton :focus {
  filter: brightness(1.14);
  transform: scale(1.1);
  transition: 150ms ease;
}

.ghostImage {
  object-fit: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, transparent 76%);
}

.reflectionAnimation {
  backdrop-filter: blur(1.4rem);
  padding: 1rem 2.2rem;
  border: 0.16rem solid rgb(255, 255, 255, 0.3);
  border-radius: 1rem;

  background: linear-gradient(120deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 49%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 71%, rgba(255,255,255,0) 100%);

  background-size: 200% 200%;
	animation: reflectionAnim 6s ease infinite;
  animation-delay: 3s;
}

@keyframes reflectionAnim {
  0%{
    background-position: 160% 50%;
  }
  70% {
    background-position: 160% 50%;
	}
  90% {
    background-position: -40% 50%;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.589);
  }
  93% {
    background-position: -40% 50%;
    box-shadow: 0 0 2rem rgba(255, 255, 255, 0.4);
  }
  100% {
    background-position: -40% 50%;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.589);
  }
}