.pageWrapper {
  margin: auto;
  max-width: 90vw;
  width: min-content;
  padding: 0.4rem 1.14rem;
  border-radius: 1.1rem;
  z-index: 10;
  /* margin-bottom: 6rem; */
}

.pageContainer {
  border-radius: 0.8rem;
  background-color: white;
}

