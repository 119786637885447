.container {
    padding: 4rem 2rem 1.4rem 2rem;
    display: flex;
    flex-direction: row;
}

.container > * {
    flex: 1;
}

.shortStats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container2 {
    padding: 15px;
    border-left-width: 5px;
    border-left-style: solid;
}