.questionsCard {
  position: relative;
  height: max-content;
  overflow: hidden; 
  flex-basis: max-content;
  background-color: #dcdcdc;
  padding: 0.1rem;
  gap: 0.1rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;

  animation: add_anim 200ms ease forwards;
}

@keyframes add_anim {
  0% {
    transform: translateY(-100%);
    opacity: 0.2;
    max-height: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    max-height: 10rem;
  }
}

.removeQuestionCardAnimation {
  position: relative;
  height: max-content;
  overflow: hidden; 
  flex-basis: max-content;
  background-color: #dcdcdc;
  padding: 0.1rem;
  gap: 0.1rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  
  animation: remove_anim 200ms ease forwards;
}

@keyframes remove_anim {
  0% {
    transform: translateY(0);
    opacity: 1;
    max-height: 10rem;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0.2;
    max-height: 0;
  }
}

