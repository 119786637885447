:root {
    --muted: #353535;
    --gray-blue: #34495e;
    --dark-gray-blue: #2c3e50;
}

@font-face {
    font-family: Harabara;
    src: url("assets/fonts/Harabara.ttf");
}

* {
    /* font-family: Harabara, sans-serif !important; */

}

h1 {
    margin-bottom: 28px;
}

h2 {
    margin-bottom: 18px;
}

button:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

table {  
    text-align: center;
}

thead {
    border-bottom: 1px solid black;
}

th {
    padding: 5px 0;
}

.brand {
    /* opacity: 0; */
    transform: translateX(-50px);
    width: 100px;
    height: 56px;
    border-radius: .4rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    animation: animeLeft 0.3s forwards;
    margin-left: -5px !important;
  }
  
  .brand img {
    width: 90%;
    height: 90%;
    border-radius: .4rem;
  }

.table-text-dots {
    max-width: 10em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nocursor {
    cursor: none;
}

.remove-a {
    color: black;
    text-decoration: none;
}

.remove-a:hover {
    color: black;
}

.children-margin-bottom > * {
    margin-bottom: 1em;
}

.title {
    font-weight: 700;
}

.header {
    font-weight: 700;
}

.font-light {
    font-weight: lighter;
}

.text-event {
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
}

.overflow-hidden {
    overflow: hidden;
}

.break-word {
    word-wrap: break-word;
}

.form-label {
    font-weight: bold;
}

.text-center, *[text-center] {
    text-align: center;
}

.status{
    font-weight: 700;
    box-shadow: none;
    max-width: 150px !important;
    margin: auto;
}

.rounded-right {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rounded-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.margin-0 {
    margin: 0;
}

.margin-bottom {
    margin-bottom: 1em;
}

.hide {
    display: none !important;
}

.show {
    display: initial;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-wrap, *[wrap] {
    flex-wrap: wrap;
}

.flex-nowrap, *[nowrap] {
    flex-wrap: nowrap;
}

.align-center {
    align-items: center;
}

.jcc {
    justify-content: center;
}

.jcsb {
    justify-content: space-between !important;
}

.jcsa {
    justify-content: space-around;
}

.jcse {
    justify-content: space-evenly;
}

.jsstart {
    justify-content: flex-start;
}

.jsend {
    justify-content: flex-end;
}

.fdrow {
    flex-direction: row;
}

.fdcolumn {
    flex-direction: column;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

.wauto {
    width: auto;
}

.fit-content {
    width: fit-content;
}

.height1 {
    height: 1em;
}

.height2 {
    height: 2em;
}

.height3 {
    height: 3em;
}

.height4 {
    height: 4em;
}

.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}

.flex3 {
    flex: 3;
}

.icon {
    cursor: pointer;
}

.icon:hover {
    filter: brightness(0.5);
    transition: .2s ease-in-out;
}

.nonclick {
    pointer-events: none;
}

.table-cell {
    display: table-cell;
}

.bg-color{
    background-color:rgba(0,0,0,0.1) !important;

}

.text_overflow_file {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
 }

 .infiniteRotateAnim {
    animation: infinite-rotate 2.5s cubic-bezier(.73,-0.01,.29,1) infinite;
}


@keyframes infinite-rotate {
    from {
        transform: rotate(-360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.payment-card {
    position: relative;
    border-radius: .6rem;
    background: linear-gradient(90deg, rgba(121,93,180,.75) 0%, rgba(57,131,190,.75) 100%);
    width: 400px;
    height: 200px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    overflow: hidden;
  }
  
  .payment-card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('/src/assets/images/card_texture.jpg');
    opacity: .06;
  }
  
  .payment-card .col-md-12, .payment-card .d-flex {
    position: relative;
    z-index: 1;
  }
  
  .payment-card p,
  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    font-size: 1.2em;
    font-weight: 600;
    color: #222;
    padding: 0 !important;
    margin: 0 !important;
  }

  .payment-card span {
    /* font-size: 1.2em; */
    font-weight: 600;
    color: #222;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  
  
  .rounded-button {
    border-radius: 10%;
    background-color: #1565C0;
    color: #fff;
    border: none;
    height: 2rem;
    width: 2rem;
  }
  
  .brand {
    width: 100px;
    height: 55px;
    border-radius: .4rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: -5px !important; */
  }
  
  .brand img {
    width: 90%;
    height: 90%;
    border-radius: .4rem;
  }

  .pointer{
    cursor: pointer;
  }
  
  .anime-right {
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.3s;
    animation: animeLeft 0.3s forwards;
  }
  
  .anime-left {
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.3s;
    animation: animeLeft 0.3s forwards;
  }
  
  @keyframes animeLeft {
    to {
      opacity: initial;
      transform: initial;
    }
  }
  
  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  
  .hvr-grow:hover,
  .hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
