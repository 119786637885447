.container {
    display: flex;
    flex-direction: row;
    padding: 75px;
    background: white;
}

.text {
    flex: 1;
}

.form {
    flex: 1;
    margin-left: 5em;
}

@media screen and (max-width: 900px) {
    .container {
        flex-direction: column;
    }

    .text {
        margin-bottom: 5em;
        text-align: center;
    }
}