.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.container:first-child {
    flex: 3 1;
}

.container:last-child {
    flex: 1 1;
}

.videoContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 22em);
    background-color: #f5f5f5;
    min-height: 100vh;
    margin-top: 0.76rem;
    padding-bottom: 2rem;
}

.circularProgress {
    margin: 3em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.courseInfoContainer::-webkit-scrollbar {
    display: none;
  }

.courseInfoContainer {
    min-width: 22em;
    max-width: 22em;
    height: 87vh;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 1em;
    margin-right: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}



.navigatorSmallScreen {
    display: none;
}

.filesPlaceholder {
    width: 100%;
    aspect-ratio: 16/9 !important;
}


@media screen and (max-width: 1200px) {
    .container {
        flex-direction: column;
    }

    .courseInfoContainer {
        display: none;
    }

    .navigatorSmallScreen {
        display: block;
    }

    .videoContainer {
        width: 100%;
    }
}