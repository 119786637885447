.profileContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 2rem 0;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;

  background-color: #f5f5f5;
  border-radius: 0.6rem;
}

.profileInfoCard {
  display: flex;
  gap: 0.6rem;
  padding: 0.4rem 1rem;
  height: max-content;
  
  background-color: rgb(255, 255, 255);
  border-radius: 0.6rem;
  border: 1px solid rgb(210, 210, 210);
  /* flex-grow: 50%; */
  flex-basis: 48%;
  flex-grow: 1;
}

.profileImage {
  width: 12rem;
  height: 12rem;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 0 0.2rem rgb(155, 201, 255);
}