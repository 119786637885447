
.MuiDrawer-root .MuiPaper-root {
  box-shadow: none!important;
  width: max-content;

  display: flex;
  flex-direction: column;
  background: rgb(240, 244, 244);
  margin: 0 auto;
  left: 4.2rem;
  margin-top: 5rem;
}