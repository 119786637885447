.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    min-width: 3rem;
}

.barContainer {
    width: 100%;
    margin-right: 5px;
}
