.status-card {
  background-color: #C5C5C5;
  border-radius: 0 .4rem .4rem 0;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.status-card p {
  font-weight: bold;
}

.status {
  padding: .4rem;
  border-radius: .4rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.status label {
  font-weight: bold;
}

.date {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: .4rem;
  font-weight: bold;
}