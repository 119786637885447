.container {
    padding: 50px 70px;
}

.cardsGroup {
    display: flex;
    flex-direction: row;
}

.cardsGroup > * {
    margin: 10px;
    flex: 1;
}

.avatar {
    width: 64px;
    height: 64px;
}

.card {
    max-width: 300px;
}