.custom-card {
  display: flex;
  /* gap: 2rem; */
  border-radius: 1rem;
  overflow: hidden;
  align-items: center;

  z-index: 3;
  position: relative;

  padding: 4rem 1rem;
  background-color: #fff;
}

.custom-card span,
.custom-card h4 {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}

.custom-card h4 {
  width: max-content;
}

.custom-card-image {
  height: 100%;
  width: 100%;

  position: absolute;
  object-fit: cover;
  object-position: center 30%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0.8;
}

.make-the-gradient {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 30%, transparent 99%);
}

.to-right {
  /* mask-image: linear-gradient(to left, rgba(0, 0, 0, 1.0) 30%, transparent 99%)!important; */
  left: auto !important;
  right: 0 !important;
}
