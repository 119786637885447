.mobile {
    width: fit-content;
    height: fit-content;
    background-color: black;
    border: 1em solid black;
    border-top-width: 4em;
    border-bottom-width: 4em;
    border-radius: 24px;
    position: relative;
    margin: 1em 0;
}

.audio {
    width: 60px;
    height: 5px;
    border-radius: 10px;
    background-color: #333;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.menu {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #333;
    position: absolute;
    bottom: -67.5px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.screen {
    width: 240px;
    height: 426px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.message {
    font-size: 1.3rem;
    text-align: center;
}