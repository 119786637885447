.pre-register-header {
    padding: 10px;
}

.pre-register-phrase {
    max-width: 50%;
    text-align: center;
}

.pre-register-support-text {
    width: 60%;
    text-align: center;
    font-size: 20px;
    padding: 20px;
}