.LoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  /* background-color: #f5f5f5; */
  /* background-color: black; */
}

.FormInputContainer {
  border-bottom: 0.1rem solid #b6b6b6;
  border-radius: 0.2rem;
  padding: 0.6rem;
  display: flex;
  gap: 1rem;
  cursor: text;
  outline: none;
  font-size: 1rem;
}

.FormInputContainer input {
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100% !important;
  /* min-width: 18rem; */
  font-family: var(--Raleway);
}
