.percentageBar[aria-selected="true"] {
    transform: scale(1.05);
}

.percentageBar[data-faded="true"] {
    filter: contrast(0.6);
}

.percentageBar {
    width: 100% !important;
    height: 1rem !important;
    margin-right: 5px;
    transition: .2s;
}

.text {
    width: 5rem;
}