.videoPlayerWrapper {
  display: flex;
  position: relative;

  height: calc(100vh - 4rem);

}

/* side bar */

.coursesSideBarContainer {
  height: 100% !important;
  z-index: 100;
  overflow: hidden;
  transition: width 0.3s ease;
  background-color: white;
}


/* player */

.PlayerContainer {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border: 2px solid #dbdada;
  position: relative;
  overflow-y: auto;
}

.OpenSideBarButton {
  display: flex;
  /* gap: 0.6rem; */
  position: absolute;
  top: 1rem;
  left: 0.2rem;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
  z-index: 100;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.4rem 1rem;
  border-radius: 0.5rem
}

.OpenSideBarButton:hover {
  opacity: 1;

}

.OpenSideBarButton span {
  max-width: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  margin-right: 0;
}

.OpenSideBarButton:hover span {
  max-width: 10rem;
  margin-right: 0.5rem;
}