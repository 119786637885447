.LoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 3rem);
  width: 100%;
}

.FormInputContainer {
  border-bottom: 0.1rem solid #b6b6b6;
  border-radius: 0.2rem;
  padding: 0.6rem;
  display: flex;
  gap: 1rem;
  cursor: text;
  outline: none;
  font-size: 1rem;
}

.FormInputContainer input {
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100% !important;
  /* min-width: 18rem; */
  font-family: var(--Raleway);
}
