.card {
    width: 100%;
    height: 100%;
    /*min-height: 600px;*/
    max-width: 410px;
    position: relative;
    overflow: visible !important;
    margin-top: 45px;
    transition: .2s !important;
    cursor: pointer;
}

.card:hover {
    transform: scale(1.02) translateY(-10px);
}

.avatar {
    width: 90px !important;
    height: 90px !important;
    position: absolute !important;
    transform: translate(50%, -50%);
    right: 50%;
}

.quotes {
    font-size: 5rem;
    width: 100%;
}

.text {
    padding: 5px 15px;
}