.snack {
    background-color: rgba(0, 0, 0, 0.8);
    color: white !important;
    padding: 5px 30px;
    width: 100vw;
    z-index: 100000 !important;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.button {
    align-self: flex-end;
}