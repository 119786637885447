.container {
    position: relative;
    display: flex;
    overflow: hidden;
    aspect-ratio: 16/9;
    background-color: black;
}

.player {
    width: 100%;
    height: 100%;
    /* align-self: center; */
    /* background-color: red; */
}

.container:hover > .controls, .container:focus > .controls {
    opacity: 1;
}

.container:hover > .mobileControls, .container:focus > .mobileControls {
    display: flex;
    opacity: 1;
}

.container:hover .buttons, .container:focus .buttons {
    pointer-events: all;
}

.buttons {
    pointer-events: none;
}

.controls {
    width: 100%;
    /* height: 100%; */
    bottom: 0;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent);
    transition: opacity 0.3s;
    opacity: 0;
    display: flex;
    flex-direction: column;
    /* z-index: 9000; */
}

.loading {
    position: absolute;
    width: 99%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
