.gridContainer {
  display: flex;
  /* overflow: hidden; */
}

.hidePreviewButton {
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  border-radius: 0.4rem;
  opacity: 0.8;
  font-weight: bold;
  height: max-content
}

.hidePreviewButton:hover {
  opacity: 1;
}
