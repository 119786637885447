.getCourseButton {
  width: max-content;
  padding: 1rem 4rem;
  border-radius: 0.8rem;
  outline: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;

  transition: 400ms 1s ease;
}

.getCourseButton:hover,
.getCourseButton :focus {
  filter: brightness(1.14);
  transform: scale(1.1);
  transition: 150ms ease;
}


.ghostImage {
  /* background-size: cover; */
  object-fit: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 10%, transparent 76%);
}